
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class DocumentDetailsModal extends Vue {
  @Prop() public headerText!: string;
  @Prop() public dialog!: boolean;
  @Prop() public notDownloadable?: boolean;
  @Prop() public downloadFile?: () => void | null;

  public mounted() {
    document.addEventListener("keydown", (e) => {
      if (this.dialog && e.key === 'Escape') {
        this.close();
      }
    });
  }

  private close() {
    this.$emit('closeDialog', false);
  }

  private doDownload() {
    if (this.downloadFile) {
      this.downloadFile();
    }
  }
}
